<template>
  <div>
    <div class="content-bottom-padded">
      <el-alert type="info" title="下载提示" show-icon :closable="false" effect="dark">
        <div>已经生成的文件只保留约二十四小时，请及时下载</div>
      </el-alert>
    </div>

    <el-container :style="{height: (wheight-180)+'px', border: '1px solid #eee'}">
      <el-main>
        <el-aside width="200">
          <el-tabs
            v-model="activeMainTab"
            @tab-click="handleMainTabClick"
            :stretch="true">
            <el-tab-pane
              v-for="tab in mainTabs"
              :key="tab.name"
              :name="tab.name">
              <span slot="label"><i :class="tab.icon"></i> {{tab.label}}</span>

              <el-tabs
                tabPosition="left"
                v-model="activeSubTabs[tab.name]"
                @tab-click="handleSubTabClick">
                <template v-for="subTab in tab.subTabs">
                  <el-tab-pane
                    v-if="checkTabPermission(subTab)"
                    :key="subTab.name"
                    :label="subTab.label"
                    :name="subTab.name">
                  </el-tab-pane>
                </template>

                <el-main :style="{ height: (wheight-183)+'px'}">
                  <!-- 保留原有表格结构 -->
                  <el-table :data="exportLogLists" width="100%" v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span
                          v-if="
                            scope.row.handle_status == 'finish' ||
                            scope.row.handle_status == 'fail'
                          "
                          >{{ scope.row.finish_date }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count"
                      :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
      </el-main>
    </el-container>

    <!-- 密码弹窗 -->
    <el-dialog
      title="请输入密码"
      :visible.sync="accountPasswordVisible"
      width="30%"
      :before-close="beforeClosePassword"
    >
      <el-form>
        <el-form-item label="密码">
          <el-input v-model="accountPassword" class="input-m" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountPasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="passwordDownload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExportLogList, ExportLogFileDown } from '../../../api/trade'
import { tabConfig } from './config'

export default {
  props: ['getStatus'],

  data() {
    return {
      activeMainTab: 'order',
      mainTabs: tabConfig,
      activeSubTabs: {
        order: 'normal_master_order',
        coupon: 'discountUser',
        shop: 'distributor_list',
        promotion: 'questionnaire_statistics',
        goods: 'distributor_items',
        user: 'members_guarantee_list',
        statistics: 'selfhandle_log',
        safety: 'black_address'
      },
      accountPasswordVisible: false,
      accountPassword: "",
      log_id: 0,
      exportLogLists: [],
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        export_type: tabConfig[0].subTabs[0].name,
        time_start_begin: '',
        time_start_end: '',
      },
    };
  },

  computed: {
    ...mapGetters(["wheight", "login_type"]),
  },

  methods: {
    // 检查tab权限
    checkTabPermission(subTab) {
      if (!subTab.permission) {
        return true;
      }
      return subTab.permission(this.login_type);
    },

    // 处理主tab点击
    handleMainTabClick(tab) {
      // 使用 tab.name 获取选中的主tab的 name
      const activeSubTab = this.activeSubTabs[tab.name]
      if (activeSubTab) {
        this.params.export_type = activeSubTab
        this.params.page = 1
        this.getExportLogLists(this.params)
      }
    },

    // 处理子tab点击
    handleSubTabClick(tab) {
      this.params.export_type = tab.name
      this.params.page = 1
      this.getExportLogLists(this.params)
    },

    // 处理分页
    handleCurrentChange(page_num) {
      this.params.page = page_num;
      this.getExportLogLists(this.params);
    },

    // 获取导出日志列表
    getExportLogLists(params) {
      this.loading = true
      ExportLogList(params).then(response => {
        this.exportLogLists = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },

    // 处理下载
    handleDown(val) {
      if (this.params.export_type === 'password_card') {
        this.accountPasswordVisible = true
        this.log_id = val
      } else {
        this.downloadFile({ log_id: val })
      }
    },

    // 密码下载
    passwordDownload() {
      if (!this.accountPassword) {
        this.$message.error("请输入密码");
        return false;
      }
      this.downloadFile({
        log_id: this.log_id,
        password: this.accountPassword
      })
    },

    // 下载文件
    downloadFile(params) {
      ExportLogFileDown(params).then(response => {
        this.accountPasswordVisible = false
        this.accountPassword = ''

        var a = document.createElement("a")
        a.href = response.data.data.csv_data
        a.download = response.data.data.file_name
        document.body.appendChild(a)
        a.click()
        a.remove()
      }).catch(err => {
        // 错误处理
      })
    },

    beforeClosePassword() {
      this.accountPassword = ''
      this.accountPasswordVisible = false
    }
  },

  mounted() {
    if (this.$route.query.active_type) {
      this.params.export_type = this.$route.query.active_type
      // 根据active_type找到对应的主tab
      for (const tab of this.mainTabs) {
        const subTab = tab.subTabs.find(s => s.name === this.$route.query.active_type)
        if (subTab) {
          this.activeSubTabs[tab.name] = this.$route.query.active_type
          break
        }
      }
    } else {
      // 添加新的初始化逻辑
      for (const tab of this.mainTabs) {
        // 找到第一个有权限的子标签
        const firstValidSubTab = tab.subTabs.find(subTab => this.checkTabPermission(subTab))
        if (firstValidSubTab) {
          this.activeSubTabs[tab.name] = firstValidSubTab.name
          if (tab.name === this.activeMainTab) {
            // 如果是当前激活的主标签，更新export_type
            this.params.export_type = firstValidSubTab.name
          }
        }
      }
    }
    this.getExportLogLists(this.params);
  },

  watch: {
    getStatus(val) {
      if (val) {
        this.getExportLogLists(this.params);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 10px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text-muted {
  color: #999;
}
</style>
